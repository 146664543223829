import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'GothamRounded-Medium';
    src: local('GothamRounded-Medium'), url(../assets/fonts/GothamRounded-Medium.ttf) format('truetype')
         local('GothamRounded-Medium'), url(../assets/fonts/GothamRounded-Medium.otf) format('opentype');
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    overflow-y: auto;

    div.ReactModal__Overlay {
      z-index: 99999;
    }

    div.ReactModal__Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #555;

      h2 {
        display: flex;
        align-items: center;
        color: #333;
        margin: 8px 0;

        svg {
          margin-right: 8px;
        }
      }

      span {
        display: flex;
        flex-direction: column;
        margin: 8px 0;
      }

      button {
        width: 100%;
        background: linear-gradient(123deg, #a6a49e, #878580);
        border-radius: 20px;
        margin: 0 8px;
        padding: 16px 8px;
        font-size: 18px;
        border: 0;
        color: #fff;

        cursor: pointer;
      }
    }
  }

  #root {
    font-family: 'GothamRoundedMedium', sans-serif;
    background: #fff;
    color: var(--white);
  }

  body,
  input,
  button,
  textarea {
    font: 600 18px Roboto, sans-serif;
    outline: 0;
  }

  :root {
    --darkGray: #555;
    --lightGray: #cdcac3;
    --white: #fbf6f0;
    --green: #d9e4dc;
  }
`;