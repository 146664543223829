import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  FiTrash,
  FiPlus,
  FiEdit,
  FiLogOut,
  FiCalendar,
  FiArchive,
} from 'react-icons/fi';
import { format, isPast, parseISO } from 'date-fns';

import api from '../../services/api';
import logo from '../../assets/images/logo.svg';

import {
  Container,
  ListWrapper,
  List,
  HeaderNavButton,
  Header,
} from './styles';
import Loader from '../../components/Loader';

interface Event {
  id: number;
  name: string;
  about: string;
  owner: string;
  event_date: string;
}

const Events: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [viewPastEvents, setViewPastEvents] = useState(false);

  const history = useHistory();

  useEffect(() => {
    api
      .get('events')
      .then((response) => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch(() => {
        localStorage.removeItem('@BeniConviteDigital:token');
        localStorage.removeItem('@BeniConviteDigital:user');
        window.location.reload();
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  async function handleEventDelete(id: string) {
    await api.delete(`events/${id}`);

    alert('Evento excluído com sucesso!');

    history.push('/');
  }

  function handleLogout() {
    localStorage.removeItem('@BeniConviteDigital:token');
    localStorage.removeItem('@BeniConviteDigital:user');

    history.push('/');
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <Header>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>

      <HeaderNavButton>
        <Link to='/' onClick={handleLogout}>
          <FiLogOut size={32} />
        </Link>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            onClick={() => setViewPastEvents(false)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: 'var(--darkGray)',
              color: 'var(--lightGray)',
              padding: '8px',
              borderRadius: '8px',
              opacity: viewPastEvents ? '1' : '0.5',
              marginRight: '4px',
            }}
          >
            <FiCalendar size={24} />
            <h6>Próximos eventos</h6>
          </span>
          <span
            onClick={() => setViewPastEvents(true)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: 'var(--darkGray)',
              color: 'var(--lightGray)',
              padding: '8px',
              borderRadius: '8px',
              marginLeft: '4px',
              opacity: viewPastEvents ? '0.5' : '1',
            }}
          >
            <FiArchive size={24} />
            <h6>Arquivo de eventos</h6>
          </span>
        </div>

        <Link to='/event/add'>
          <FiPlus size={32} />
        </Link>
      </HeaderNavButton>

      <ListWrapper>
        <List>
          <li>
            <span>Nome do Evento</span>
            <span>Data</span>
            <span>Ações</span>
          </li>

          {events.map((event) => {
            return (
              (viewPastEvents
                ? isPast(new Date(event.event_date))
                : !isPast(new Date(event.event_date))) && (
                <li key={event.id}>
                  <Link to={`/event/${event.id}`}>
                    <span>{event.name}</span>
                  </Link>
                  <span>
                    {format(parseISO(event.event_date), 'dd/MM/yyyy')}
                  </span>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to={`/event/${event.id}/edit`}>
                      <FiEdit size={20} />
                    </Link>
                    <FiTrash
                      onClick={() => handleEventDelete(String(event.id))}
                      size={20}
                    />
                  </span>
                </li>
              )
            );
          })}
        </List>
      </ListWrapper>
    </Container>
  );
};

export default Events;
