import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Events from './pages/Events';
import AddEvent from './pages/AddEvent';
import EventDetail from './pages/EventDetail';
import AddGuest from './pages/AddGuest';
import Invitation from './pages/Invitation';
import Presence from './pages/Presence';
import SignIn from './pages/SignIn';
import EditEvent from './pages/EditEvent';
import EventGuests from './pages/EventGuests';
import GuestList from './pages/GuestList';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={SignIn} />
        <Route path='/events/' component={Events} />
        <Route path='/event/add' component={AddEvent} />
        <Route path='/event/:id' exact component={EventDetail} />
        <Route path='/event/:id/guests' exact component={EventGuests} />
        <Route path='/event/:id/edit' component={EditEvent} />
        <Route path='/event/:id/guest_list' component={GuestList} />
        <Route path='/event/:id/add-guest' component={AddGuest} />
        <Route path='/invitation/:event_id/:guest_id' component={Invitation} />
        <Route path='/presence/:id' component={Presence} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
