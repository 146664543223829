import React, { useState, useEffect } from 'react';
import {
  FiArrowLeft,
  FiCheckCircle,
  FiCircle,
  FiTrash,
  FiSend,
  FiUserPlus,
} from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { TileLayer, Marker, MapContainer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import api from '../../services/api';
import mapIcon from '../../utils/mapIcon';
import logo from '../../assets/images/logo.svg';

import {
  Container,
  EventContainer,
  EventWrapper,
  Header,
  HeaderNavButton,
  GuestsContainer,
  GuestsList,
  Guest,
  Map,
} from './styles';
import Loader from '../../components/Loader';

interface Event {
  id: string;
  name: string;
  about: string;
  tour: string;
  password: string;
  owner: string;
  owner_contact: string;
  gift_list: string;
  info: string;
  event_date: string;
  confirmation_limit_date: string;
  latitude: string;
  longitude: string;
  guests: Array<Guests>;
  images: Array<{
    id: string;
    url: string;
  }>;
}

interface RouteParams {
  id: string;
}

interface Guests {
  id: string;
  name: string;
  phone: string;
  table: string;
  confirmed: boolean;
}

const EventDetail: React.FC<RouteParams> = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<RouteParams>();
  const [event, setEvent] = useState<Event>();
  const [confirmedGuests, setConfirmedGuests] = useState<Guests[]>([]);

  const history = useHistory();

  useEffect(() => {
    api
      .get(`/events/${params.id}`)
      .then((response) => {
        setEvent(response.data);
        setLoading(false);
      })
      .catch(() => {
        localStorage.removeItem('@BeniConviteDigital:token');
        localStorage.removeItem('@BeniConviteDigital:user');
      });
  }, [params.id]);

  useEffect(() => {
    const getConfirmedGuests = event?.guests.filter((guest) => guest.confirmed);

    if (getConfirmedGuests) {
      setConfirmedGuests(getConfirmedGuests);
    }
  }, [event]);

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  async function handleGuestDelete(id: string) {
    await api.delete(`guests/${id}`);

    alert('Convidado excluído com sucesso!');

    history.push(`/event/${event?.id}`);
  }

  async function handleSendInvitation(id: string) {
    const guest = await (await api.get(`guests/${id}`)).data;

    const whatsAppMessage = encodeURIComponent(`Olá, ${guest.name}!\n
Você foi convidado(a) para ${event?.name}.\n
Para visualizar seu convite e confirmar sua presença, clique no link abaixo.\n
${process.env.REACT_APP_URL}/invitation/${event?.id}/${guest.id}\n
*Responda esta mensagem para habilitar o link do seu convite*`);

    window.open(
      `https://api.whatsapp.com/send?phone=55${guest.phone}&text=${whatsAppMessage}`,
      '_blank'
    );
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <Header>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>
      <HeaderNavButton>
        <Link to='/'>
          <FiArrowLeft size={32} />
        </Link>
        <Link to={`/event/${params.id}/add-guest`}>
          <FiUserPlus size={32} />
        </Link>
      </HeaderNavButton>
      <EventWrapper>
        <EventContainer>
          <h1>{event?.name}</h1>
          {event?.images.map((image) => (
            <img key={image.id} src={image.url} alt={event.name} />
          ))}
          <span>
            <label>Sobre o evento: </label>
            <strong>{event?.about}</strong>
          </span>
          <span>
            <label>Senha para lista de convidados: </label>
            <strong>{event?.password ? event?.password : 'Sem senha'}</strong>
          </span>
          <span>
            <label>Responsável: </label>
            <strong>{event?.owner}</strong>
          </span>

          <span>
            <label>Data do evento: </label>
            <strong>
              {event &&
                format(
                  utcToZonedTime(
                    parseISO(event.event_date),
                    'America/Sao_Paulo'
                  ),
                  "dd/MM/yyyy' às 'HH:mm'h'"
                )}
            </strong>
          </span>
          <span>
            <label>Data limite para confirmação de presença: </label>
            <strong>
              {event &&
                format(
                  utcToZonedTime(
                    parseISO(event.confirmation_limit_date),
                    'America/Sao_Paulo'
                  ),
                  'dd/MM/yyyy'
                )}
            </strong>
          </span>

          <span>
            <label>Localização: </label>
            {event && (
              <Map>
                <MapContainer
                  center={[Number(event.latitude), Number(event.longitude)]}
                  zoom={15}
                  style={{ width: '100%', height: '100%' }}
                >
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                  />

                  <Marker
                    key={event.id}
                    position={[Number(event.latitude), Number(event.longitude)]}
                    icon={mapIcon}
                  ></Marker>
                </MapContainer>
              </Map>
            )}
          </span>

          <GuestsContainer>
            <h1>Convidados:</h1>
            <GuestsList>
              {event?.guests.map((guest) => (
                <Guest key={guest.id}>
                  {guest.confirmed ? (
                    <FiCheckCircle size={14} color='#39CC83' />
                  ) : (
                    <FiCircle size={14} color='#FF669D' />
                  )}
                  <span>{guest.name}</span>

                  <FiSend
                    onClick={() => handleSendInvitation(guest.id)}
                    size={14}
                  />
                  <FiTrash
                    onClick={() => handleGuestDelete(guest.id)}
                    size={14}
                  />
                </Guest>
              ))}
            </GuestsList>
            <h2>Total de convidados: {event?.guests.length}</h2>
            <h3>Total de confirmados: {confirmedGuests.length}</h3>

            <div>
              <Link to={`/event/${params.id}/guests`}>
                Visualizar / Exportar lista de convidados
              </Link>
            </div>
          </GuestsContainer>
        </EventContainer>
      </EventWrapper>
    </Container>
  );
};

export default EventDetail;
