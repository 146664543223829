import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px 20px;
  background: var(--green);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.header`
  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderNavButton = styled.button`
  max-width: 600px;
  width: 100%;
  height: 60px;
  background: transparent;
  border: 0;
  margin: 20px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a, div {
    width: 60px;
    height: 60px;
    background: var(--darkGray);
    border: 0;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    svg {
      color: var(--white);
    }
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 600px;
  height: auto;
  background: transparent;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormField = styled.div`
  height: auto;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 8px 0;
  background: var(--darkGray);
  color: var(--white);
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  img {
    max-width: 100%;
    padding: 8px 0;
  }
`;

export const InputLabel = styled.label`
  font-size: 14px;
  color: var(--lightGray);
  margin-bottom: 4px;
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--green);
  color: var(--white);
  font-weight: normal;
  outline: 0;

  &::placeholder {
    color: var(--white);
    opacity: 0.2;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  max-height: 80px;
  border: 0;
  background: transparent;
  border-bottom: 1px solid var(--green);
  color: var(--white);
  font-weight: normal;
  outline: 0;
  resize: none;

  &::placeholder {
    color: var(--white);
    opacity: 0.2;
  }
`;
