import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FiCheck, FiCheckSquare, FiLogIn, FiMinusCircle, FiPlus, FiSquare } from 'react-icons/fi';

import api from '../../services/api';

import { Header, Loader } from './styles';
import logo from '../../assets/images/logo.svg';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';

interface RouteParams {
  id: string;
}

interface GuestKeys {
  [key: string]: string | number | boolean | undefined;
}

interface Guest extends GuestKeys {
  id?: string;
  name: string;
  phone: string;
  table: string;
  confirmed: boolean;
}

interface Event {
  id: string;
  name: string;
  about: string;
  tour: string;
  owner: string;
  owner_contact: string;
  gift_list: string;
  info: string;
  event_date: string;
  confirmation_limit_date: string;
  place: string;
  latitude: string;
  longitude: string;
  password: string;
  images: Array<{
    id: string;
    url: string;
  }>;
}

const GuestList: React.FC = () => {
  const [password, setPassword] = useState('');
  const [signed, setSigned] = useState(false);
  const [guestList, setGuestList] = useState<Guest[]>();
  const [event, setEvent] = useState<Event>();
  const [loading, setLoading] = useState(false);

  const params = useParams<RouteParams>();
  const { id } = params;

  useEffect(() => {
    setSigned(false);
  }, [id]);

  const notifyWrongPassword = () => {
    toast.error('Senha incorreta, tente novamente.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSignIn = async (): Promise<void> => {
    setLoading(true);

    try {
      const { data } = await api.get(`/events/guest_list/${id}/${password}`);

      if (data) {
        setEvent(data);
        setGuestList(data.guests);
        setSigned(true);
        setLoading(false);
        return;
      }
    } catch (err) {
      setLoading(false);
      setSigned(false);
      notifyWrongPassword();
    }
  };

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!guestList) {
      return;
    }

    const key = event.currentTarget.name;
    const newGuestList = [...guestList];
    newGuestList[index][key] = event.currentTarget.value;
    setGuestList(newGuestList);
  };

  const addFormFields = () => {
    if (guestList) {
      setGuestList([
        ...guestList,
        { name: '', phone: '', table: '', confirmed: false },
      ]);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (guestList) {
        const response = await api.put(`/guests/update-list/${id}`, guestList);

        if (response.status === 200) {
          setLoading(false);

          toast.success('Lista atualizada com successo!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }

      handleSignIn();
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao atualizar a lista de convidados.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const removeFormFields = (index: number) => {
    if (guestList) {
      const newGuestList = [...guestList];
      newGuestList.splice(index, 1);
      setGuestList(newGuestList);
    }
  };

  return (
    <Container className='container text-center'>
      <Header style={{ margin: '0 auto' }}>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>
      <h4 style={{ color: 'var(--darkGray)', margin: '24px 0' }}>
        Área do Cliente - Lista de convidados
      </h4>
      <ToastContainer />
      {signed ? (
        <Form
          onSubmit={(event) => handleSubmit(event)}
          className='col-sm-12 col-md-6 offset-md-3'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '18px',
            }}
          >
            <div>
              <h4 style={{ color: 'var(--darkGray)', marginBottom: '22px' }}>
                {event?.name}
              </h4>
            </div>
          </div>
          {guestList?.map((element, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
              key={index}
            >
              <div style={{ margin: '0 4px' }}>
                <FormGroup>
                  {!index && (
                    <Label style={{ color: 'var(--darkGray)' }}>Nome</Label>
                  )}
                  <Input
                    name='name'
                    value={element.name}
                    placeholder='Nome do(a) convidado(a)'
                    onChange={(event) => handleChange(index, event)}
                    autoFocus
                    required
                  />
                </FormGroup>
              </div>
              <div style={{ margin: '0 4px' }}>
                <FormGroup>
                  {!index && (
                    <Label style={{ color: 'var(--darkGray)' }}>WhatsApp</Label>
                  )}
                  <Input
                    name='phone'
                    type='tel'
                    value={element.phone}
                    placeholder='WhatsApp do(a) convidado(a)'
                    onChange={(event) => handleChange(index, event)}
                    required
                  />
                </FormGroup>
              </div>
              <div style={{ margin: '0 4px', width: '80px' }}>
                <FormGroup>
                  {!index && (
                    <Label style={{ color: 'var(--darkGray)' }}>Mesa</Label>
                  )}
                  <Input
                    name='table'
                    value={element.table}
                    placeholder='Nº'
                    onChange={(event) => handleChange(index, event)}
                  />
                </FormGroup>
              </div>
              <div style={{ margin: '0 4px' }}>
                <FormGroup>
                  {!index && (
                    <Label style={{ color: 'var(--darkGray)' }}>Presença</Label>
                  )}
                  <div
                    style={{
                      width: '80px',
                      height: '38px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {element.confirmed ? (
                      <FiCheckSquare size={24} color='#333' />
                    ) : (
                      <FiSquare size={24} color='#333' />
                    )}
                  </div>
                </FormGroup>
              </div>
              <div style={{ margin: '0 4px' }}>
                <FormGroup>
                  <Button
                    type='button'
                    color='danger'
                    className='button remove'
                    onClick={() => removeFormFields(index)}
                    style={{ fontWeight: 'bold' }}
                  >
                    <FiMinusCircle size={24} />
                  </Button>
                </FormGroup>
              </div>
            </div>
          ))}
          <FormGroup
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '0 4px',
            }}
          >
            <Button color='primary' outline onClick={() => addFormFields()}>
              <FiPlus size={24} />
            </Button>
            <Label
              style={{
                color: 'var(--darkGray)',
                margin: '0',
                fontSize: '14px',
              }}
            >
              {guestList?.reduce((acc, guest) => {
                if (guest.confirmed) acc++;
                return acc;
              }, 0)}{' '}
              convidado(s) confirmados. Total de {guestList?.length}.
            </Label>
            <Button
              type='submit'
              color='primary'
              style={{ display: 'flex', alignItems: 'center' }}
              disabled={loading}
            >
              {loading ? (
                <Loader style={{ marginRight: '8px' }} />
              ) : (
                <FiCheck style={{ marginRight: '8px' }} />
              )}
              Salvar lista
            </Button>
          </FormGroup>
        </Form>
      ) : (
        <Form
          inline
          className='col-sm-12 col-md-6 offset-md-3'
          onSubmit={(event) => event.preventDefault()}
          style={{ display: 'flex' }}
        >
          <Input
            type='text'
            name='password'
            value={password}
            placeholder='Informe a senha do seu evento'
            onChange={(event) => setPassword(event.target.value)}
            style={{ marginRight: '18px' }}
          />
          <Button
            color='primary'
            type='submit'
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={handleSignIn}
            disabled={loading}
          >
            {loading ? (
              <Loader style={{ marginRight: '8px' }} />
            ) : (
              <FiLogIn style={{ marginRight: '8px' }} />
            )}
            Acessar
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default GuestList;
