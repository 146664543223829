import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FiArrowLeft, FiCheck } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import {
  Container,
  FormWrapper,
  FormField,
  InputLabel,
  Input,
  TextArea,
  Header,
  HeaderNavButton,
} from './styles';
import logo from '../../assets/images/logo.svg';
import { utcToZonedTime } from 'date-fns-tz';

const AddEvent: React.FC = () => {
  const [eventName, setEventName] = useState('');
  const [eventAbout, setEventAbout] = useState('');
  const [eventTour, setEventTour] = useState('');
  const [eventOwner, setEventOwner] = useState('');
  const [eventOwnerContact, setEventOwnerContact] = useState('');
  const [eventGiftList, setEventGiftList] = useState('');
  const [eventInfo, setEventInfo] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventConfirmationDate, setEventConfirmationDate] = useState('');
  const [eventPlace, setEventPlace] = useState('');
  const [eventLatitude, setEventLatitude] = useState('');
  const [eventLongitude, setEventLongitude] = useState('');
  const [eventImages, setEventImages] = useState<File[]>([]);
  const [eventImagesPreview, setEventImagesPreview] = useState<string[]>([]);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  function handleSelectImages(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const selectedImages = Array.from(event.target.files);

    setEventImages(selectedImages);

    const selectedImagesPreview = selectedImages.map((image) => {
      return URL.createObjectURL(image);
    });

    setEventImagesPreview(selectedImagesPreview);
  }

  async function handleSubmit(event: FormEvent) {
    event?.preventDefault();

    const data = new FormData();

    data.append('name', eventName);
    data.append('about', eventAbout);
    data.append('tour', eventTour);
    data.append('owner', eventOwner);
    data.append('owner_contact', eventOwnerContact);
    data.append('gift_list', eventGiftList);
    data.append('info', eventInfo);
    data.append(
      'event_date',
      String(utcToZonedTime(eventDate, 'America/Sao_Paulo'))
    );
    data.append(
      'confirmation_limit_date',
      String(utcToZonedTime(eventConfirmationDate, 'America/Sao_Paulo'))
    );
    data.append('place', eventPlace);
    data.append('latitude', eventLatitude);
    data.append('longitude', eventLongitude);

    eventImages.forEach((image) => {
      data.append('images', image);
    });

    const response = await api.post('events', data);

    const { id, password } = response.data;

    alert('Evento cadastrado com sucesso!');

    const whatsAppMessage = encodeURIComponent(`Olá, ${eventOwner}!\n
    Segue o link para a página de cadastro dos convidados para ${eventName}.\n
    ${process.env.REACT_APP_URL}/event/${id}/guest_list\n
    Para acessar a lista de convidados, utilize a senha: ${password}.\n
    *Responda esta mensagem caso o link não estiver clicável*`);

    window.open(
      `https://api.whatsapp.com/send?phone=55${eventOwnerContact}&text=${whatsAppMessage}`,
      '_blank'
    );

    history.push('/');
  }

  return (
    <Container>
      <Header>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>
      <HeaderNavButton>
        <Link to='/'>
          <FiArrowLeft size={30} />
        </Link>
        <div onClick={handleSubmit}>
          <FiCheck size={30} />
        </div>
      </HeaderNavButton>
      <FormWrapper>
        <FormField>
          <InputLabel>Nome do evento</InputLabel>
          <Input
            placeholder='Ex: Casamento de João e Maria'
            value={eventName}
            onChange={(event) => setEventName(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Descrição</InputLabel>
          <TextArea
            placeholder='Ex: Sobre o casamento de João e Maria'
            value={eventAbout}
            onChange={(event) => setEventAbout(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Data do Evento</InputLabel>
          <Input
            type='datetime-local'
            value={eventDate}
            onChange={(event) => setEventDate(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Data limite para confirmação de presença</InputLabel>
          <Input
            type='datetime-local'
            value={eventConfirmationDate}
            onChange={(event) => setEventConfirmationDate(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Responsável</InputLabel>
          <Input
            placeholder='Ex: Casamento de João e Maria'
            value={eventOwner}
            onChange={(event) => setEventOwner(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>WhatsApp do Responsável</InputLabel>
          <Input
            placeholder='Ex: 19991929394'
            value={eventOwnerContact}
            onChange={(event) => setEventOwnerContact(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Nome do local</InputLabel>
          <Input
            placeholder='Ex: Chácara das rosas'
            value={eventPlace}
            onChange={(event) => setEventPlace(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Latitude</InputLabel>
          <Input
            placeholder='Ex: -22.55462'
            value={eventLatitude}
            onChange={(event) => setEventLatitude(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Longitude</InputLabel>
          <Input
            placeholder='Ex: -45.55893'
            value={eventLongitude}
            onChange={(event) => setEventLongitude(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Tour 360</InputLabel>
          <Input
            placeholder='Ex: http://meutour.com.br'
            value={eventTour}
            onChange={(event) => setEventTour(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Lista de Presente</InputLabel>
          <Input
            placeholder='Ex: http://minhalista.com.br'
            value={eventGiftList}
            onChange={(event) => setEventGiftList(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Informações</InputLabel>
          <Input
            placeholder='Ex: Informações extras'
            value={eventInfo}
            onChange={(event) => setEventInfo(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Imagem</InputLabel>
          {eventImagesPreview.map((image) => (
            <img key={image} src={image} alt={eventName} />
          ))}
          <Input
            onChange={handleSelectImages}
            type='file'
            name=''
            id='image[]'
          />
        </FormField>
      </FormWrapper>
    </Container>
  );
};

export default AddEvent;
