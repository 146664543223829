import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  padding: 0 20px 20px;
  background: var(--green);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.header`
  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const HeaderNavButton = styled.button`
  max-width: 600px;
  width: 100%;
  height: 60px;
  background: transparent;
  border: 0;
  margin: 20px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    width: 60px;
    height: 60px;
    background: var(--darkGray);
    border: 0;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
    color: var(--white);
  }
  }
`;

export const EventWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  background: transparent;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EventContainer = styled.main`
  width: 100%;
  height: auto;
  padding: 24px;
  background: var(--darkGray);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    width: 100%;
    font-size: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  span {
    margin: 8px 0;
    width: 100%;
    font-size: 16px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-size: 14px;
      color: var(--lightGray);
      padding: 4px 0;
    }

    strong {
      font-weight: bold;
      font-size: 18px;
      color: var(--white);
    }
  }
`;

export const GuestsContainer = styled.div`
  width: 100%;


  h1 {
    width: 100%;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  h2 {
    width: 100%;
    font-size: 26px;
    margin: 16px 0;
    text-align: center;
  }
  
  h3 {
    width: 100%;
    font-size: 22px;
    margin: 8px 0;
    text-align: center;
  }
`;

export const GuestsList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  div.ReactModal__Content, div.ReactModal__Overlay {
    z-index: 99;
  }
`;

export const Guest = styled.div`
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 8px;
    width: 16px;
    cursor: pointer;

    &:first-child {
      cursor: default;
    }
  }

  &:last-child {
    border: 0;
  }
`;