import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import QRCode from 'qrcode.react';
import {
  FiMapPin,
  FiCheckCircle,
  FiXCircle,
  FiCalendar,
  FiGift,
  FiAlertCircle,
  FiUser,
  FiGlobe,
} from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

import Loader from '../../components/Loader';

import api from '../../services/api';
import logoImg from '../../assets/images/logo.svg';

import {
  Container,
  EventContainer,
  EventHeader,
  EventActions,
  EventDescription,
  EventTitle,
  EventLocation,
  EventInformation,
  EventDate,
  EventOwner,
  EventGiftList,
  NotGoingButton,
  GoingButton,
  Separator,
  Background,
  BackgroundImageOpacity,
  InvitationBackground,
  BackgroundImageBlur,
  Logo,
} from './styles';

interface RouteParams {
  event_id: string;
  guest_id: string;
}

interface Event {
  id: string;
  name: string;
  about: string;
  tour: string;
  owner: string;
  owner_contact: string;
  gift_list: string;
  info: string;
  event_date: string;
  confirmation_limit_date: string;
  place: string;
  latitude: string;
  longitude: string;
  images: Array<{
    id: string;
    url: string;
  }>;
}

interface Guest {
  id: string;
  name: string;
  phone: string;
  confirmed: boolean;
  presence: boolean;
}

interface Image {
  id: string;
  url: string;
}

const Invitation: React.FC<RouteParams> = () => {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<Event>();
  const [guest, setGuest] = useState<Guest>();
  const [eventImage, setEventImage] = useState<Image>();
  const [formattedDate, setFormattedDate] = useState('');
  const params = useParams<RouteParams>();
  const { event_id, guest_id } = params;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
    },
  };

  ReactModal.setAppElement('#root');

  const [showInfoModal, hideInfoModal] = useModal(
    () => (
      <ReactModal isOpen ariaHideApp={false} style={customStyles}>
        <h2>
          <FiAlertCircle size={28} color='#333' /> Informações
        </h2>

        {event?.info ? (
          event.info.match(/http/) ? (
            <span>
              <img
                src={event.info}
                alt={event.name}
                style={{ maxWidth: '450px' }}
              />
            </span>
          ) : (
            <span>{event.info}</span>
          )
        ) : (
          <span>
            Querida família e amigos, devido ao momento que estamos passando,
            para a segurança de todos vale lembrar:
            <span>- Devemos evitar o contato físico</span>
            <span>- Se preferir, permaneça de máscara</span>
            <span>- Use e abuse do álcool em gel</span>
          </span>
        )}
        <button onClick={hideInfoModal}>Fechar</button>
      </ReactModal>
    ),
    [event]
  );

  // const [showGiftModal, hideGiftModal] = useModal(() => (
  //   <ReactModal isOpen ariaHideApp={false} style={customStyles}>
  //     <h2>
  //       <FiGift size={28} color='#333' />
  //       Lista de presente
  //     </h2>
  //     <span>
  //       Querido convidado, caso queira nos agraciar com algum presente,
  //       gostaríamos que o mesmo fosse convertido em dinheiro, pois o nosso
  //       cantinho já está mobiliado, mas claro, sua presença é nosso maior
  //       presente.
  //     </span>
  //     <span>Mariellen e Elias</span>

  //     <span>Chave Pix: elias@asystec.com.br</span>
  //     <button onClick={hideGiftModal}>Fechar</button>
  //   </ReactModal>
  // ));

  // useEffect(() => {
  //   api({
  //     method: 'POST',
  //     url: 'https://api.short.cm/links',
  //     headers: {
  //       accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       Authorization: '0rFuCEgGYlZBcPBxzcHn6pnMbABp5kol',
  //     },
  //     data: {
  //       originalURL:
  //         'https://www.beniconvitedigital.com.br/invitation/948dab2d-168f-433c-8fa7-adc5553b12bf/b0e370aa-aa2e-4bcd-9653-261cc11ecf6e',
  //       domain: 'go.beniconvitedigital.com.br',
  //     },
  //   }).then((response) => {
  //     console.log(response.data);
  //   });
  // }, []);

  useEffect(() => {
    function getEvent() {
      return api({
        method: 'get',
        url: `/events/${event_id}`,
      });
    }

    function getGuest() {
      return api({
        method: 'get',
        url: `/guests/${guest_id}`,
      });
    }

    Promise.all([getEvent(), getGuest()]).then(function (results) {
      setEvent(results[0].data);
      setGuest(results[1].data);
      setLoading(false);
    });
  }, [event_id, guest_id]);

  useEffect(() => {
    event?.images.map((image) => {
      return setEventImage(image);
    });
  }, [event]);

  useEffect(() => {
    if (event?.event_date) {
      const parsedDate = parseISO(event.event_date);
      const zonedDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');
      setFormattedDate(
        format(zonedDate, `dd 'de' MMMM 'de' yyyy 'às' HH'h'mm`, {
          locale: ptBR,
          timeZone: 'America/Sao_Paulo',
        })
      );
    }
  }, [event?.event_date]);

  async function handleConfirmPresence() {
    const guest_id = guest?.id;

    await api.put(`guests/${guest_id}`);

    alert('Presença confirmada!');

    window.location.reload();
  }

  async function handleUnconfirmPresence() {
    const guest_id = guest?.id;

    await api.put(`guests/${guest_id}`);

    alert('Obrigado por informar!');

    window.location.reload();
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{event?.name}</title>
        <meta name='description' content={event?.about} />
        <meta name='og:url' content={`${process.env.REACT_APP_URL}`} />
        <meta property='og:title' content={event?.name} />
        <meta property='og:description' content={event?.about} />
        {event?.images.map((image) => (
          <meta key={image.id} property='og:image' content={image.url} />
        ))}
        <meta name='og:type' content='website' />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Background />
          <BackgroundImageOpacity src={eventImage?.url} alt={event?.name} />
          <BackgroundImageBlur
            style={{ backgroundImage: `url(${eventImage?.url})` }}
          />
          <InvitationBackground>
            <Container>
              <EventHeader key={eventImage?.id} src={eventImage?.url} />

              <EventContainer>
                <EventActions>
                  <NotGoingButton
                    disabled={guest?.confirmed ? false : true}
                    onClick={handleUnconfirmPresence}
                  >
                    <FiXCircle size={36} />
                    <span>Não vou!</span>
                  </NotGoingButton>
                  <GoingButton
                    disabled={guest?.confirmed ? true : false}
                    onClick={handleConfirmPresence}
                  >
                    <FiCheckCircle size={36} />
                    <span>Eu vou!</span>
                  </GoingButton>
                </EventActions>
                <EventDescription>
                  <EventTitle>{event?.name}</EventTitle>
                  <EventLocation>
                    <a
                      rel='noreferrer'
                      target='_blank'
                      href={`https://www.google.com/maps/dir/?api=1&destination=${event?.latitude},${event?.longitude}`}
                    >
                      <FiMapPin size={20} color='#9cc1a6' />
                      <span style={{ fontSize: 16 }}>{event?.place}</span>
                    </a>
                  </EventLocation>

                  {event?.tour && (
                    <a href={event.tour} rel='noreferrer' target='_blank'>
                      <FiGlobe size={20} color='#9cc1a6' />
                      Tour Virtual 360º
                    </a>
                  )}
                </EventDescription>

                <Separator />

                <EventDate>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 8,
                    }}
                  >
                    <FiCalendar size={20} color='#9cc1a6' />
                    <span style={{ marginLeft: 8, fontSize: 16 }}>
                      Data e Hora
                    </span>
                  </div>
                  <span style={{ fontWeight: 'bold' }}>{formattedDate}</span>
                </EventDate>

                <Separator />

                {event?.gift_list && (
                  <EventGiftList>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        href={event.gift_list}
                        rel='noreferrer'
                        target='_blank'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          color: '#555',
                          textDecoration: 'none',
                        }}
                      >
                        <FiGift size={20} color='#9cc1a6' />
                        <span style={{ marginLeft: 8, lineHeight: '24px' }}>
                          Lista de Presentes
                        </span>
                      </a>
                    </div>
                  </EventGiftList>
                )}

                <Separator />

                <EventInformation onClick={showInfoModal}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FiAlertCircle size={20} color='#9cc1a6' />
                    <span style={{ marginLeft: 8 }}>Informações</span>
                  </div>
                </EventInformation>

                <Separator />

                <EventOwner>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 8,
                    }}
                  >
                    <FiUser size={20} color='#9cc1a6' />
                    <span style={{ marginLeft: 8, fontSize: 16 }}>
                      Organizador
                    </span>
                  </div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=55${event?.owner_contact}&text=Olá, ${event?.owner}!\n
                    Estou entrando em contato pelo evento ${event?.name}.`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    <FaWhatsapp size={20} color='#9cc1a6' />
                    {event?.owner}
                  </a>
                </EventOwner>

                <Separator />

                {guest?.confirmed && (
                  <QRCode
                    value={`${process.env.REACT_APP_API}/guests/${guest_id}/confirm`}
                    size={200}
                    bgColor='transparent'
                    fgColor='#555'
                    style={{ marginTop: 16, marginBottom: 16 }}
                  />
                )}
                <Logo src={logoImg} alt='Beni Convite Digital' />
              </EventContainer>
            </Container>
          </InvitationBackground>
        </>
      )}
    </HelmetProvider>
  );
};

export default Invitation;
