import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FiCheck, FiX, FiAlertTriangle } from 'react-icons/fi';
import api from '../../services/api';

import {
  Container,
  AuthorizedMessage,
  NotFoundMessage,
  UnauthorizedMessage,
} from './styles';
import Loader from '../../components/Loader';

interface RouteParams {
  id: string;
}

interface Guest {
  id: string;
  name: string;
  phone: string;
  confirmed: boolean;
  presence: boolean;
}

const Presence: React.FC<RouteParams> = () => {
  const [loading, setLoading] = useState(true);
  const [guest, setGuest] = useState<Guest>();
  const { id } = useParams<RouteParams>();

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  useEffect(() => {
    api
      .put(`/guests/${id}/confirm`)
      .then((guest) => {
        if (guest.status === 401) {
          console.log('Negado');
        }
        setGuest(guest.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return loading ? (
    <Loader />
  ) : (
    <Container>
      {!guest ? (
        <NotFoundMessage>
          <h1>Convidado não encontrado!</h1>
          <div>
            <FiX size={45} />
          </div>
        </NotFoundMessage>
      ) : guest.confirmed && !guest.presence ? (
        <AuthorizedMessage>
          <h1>{guest?.name}</h1>
          <span>Presença confirmada com sucesso!</span>
          <div>
            <FiCheck size={45} />
          </div>
        </AuthorizedMessage>
      ) : guest.presence ? (
        <UnauthorizedMessage>
          <h1>Convidado já está no evento!</h1>
          <div>
            <FiAlertTriangle size={45} />
          </div>
        </UnauthorizedMessage>
      ) : (
        <NotFoundMessage>
          <h1>Convidado não encontrado!</h1>
          <div>
            <FiX size={45} />
          </div>
        </NotFoundMessage>
      )}
    </Container>
  );
};

export default Presence;
