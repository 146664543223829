/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  FiArrowLeft,
  FiCheckCircle,
  FiCircle,
  FiDownload,
} from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';

import api from '../../services/api';
import logo from '../../assets/images/logo.svg';

import {
  Container,
  EventContainer,
  EventWrapper,
  Header,
  HeaderNavButton,
  GuestsContainer,
  GuestsList,
  Guest,
} from './styles';
import Loader from '../../components/Loader';

interface Event {
  id: string;
  name: string;
  about: string;
  owner: string;
  event_date: string;
  confirmation_limit_date: string;
  latitude: string;
  longitude: string;
  guests: Array<{
    id: string;
    name: string;
    phone: string;
    confirmed: boolean;
  }>;
  images: Array<{
    id: string;
    url: string;
  }>;
}

interface RouteParams {
  id: string;
}

interface Guests {
  id: string;
  name: string;
  phone: string;
  confirmed: boolean;
}

const EventDetail: React.FC<RouteParams> = () => {
  const [loading, setLoading] = useState(true);
  const params = useParams<RouteParams>();
  const [event, setEvent] = useState<Event>();
  const [CSVString, setCSVString] = useState('');
  const [confirmedGuests, setConfirmedGuests] = useState<Guests[]>([]);

  const history = useHistory();

  useEffect(() => {
    api
      .get(`/events/${params.id}`)
      .then((response) => {
        setEvent(response.data);
        setLoading(false);
      })
      .catch(() => {
        localStorage.removeItem('@BeniConviteDigital:token');
        localStorage.removeItem('@BeniConviteDigital:user');
      });
  }, [params.id]);

  useEffect(() => {
    const getConfirmedGuests = event?.guests.filter((guest) => guest.confirmed);

    if (getConfirmedGuests) {
      setConfirmedGuests(getConfirmedGuests);
    }
  }, [event]);

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  function exportToExcel() {
    if (event) {
      const guests = event?.guests.map((guest) => {
        const guestInfo = `${guest.name},${guest.phone},${guest.confirmed}`;

        return guestInfo;
      });

      let CSVString = guests
        ?.join('\n')
        .replaceAll('true', 'Sim')
        .replaceAll('false', 'Não');

      CSVString = `Nome do convidado,Telefone,Confirmou presença\n` + CSVString;

      return setCSVString(CSVString);
    }
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <Header>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>
      <HeaderNavButton>
        <Link to='/'>
          <FiArrowLeft size={32} />
        </Link>
        <a
          href={`data:text/csvcharset=utf-8,${encodeURIComponent(CSVString)}`}
          onClick={exportToExcel}
          download='convidados.csv'
        >
          <FiDownload size={32} />
        </a>
      </HeaderNavButton>
      <EventWrapper>
        <EventContainer>
          <h1>{event?.name}</h1>
          <GuestsContainer>
            <h1>Lista de convidados</h1>
            <GuestsList>
              {event?.guests.map((guest) => (
                <Guest key={guest.id}>
                  {guest.confirmed ? (
                    <FiCheckCircle size={14} color='#39CC83' />
                  ) : (
                    <FiCircle size={14} color='#FF669D' />
                  )}
                  <span>{guest.name}</span>
                </Guest>
              ))}
            </GuestsList>
            <h2>Total de convidados: {event?.guests.length}</h2>
            <h3>Total de confirmados: {confirmedGuests.length}</h3>
          </GuestsContainer>
        </EventContainer>
      </EventWrapper>
    </Container>
  );
};

export default EventDetail;
