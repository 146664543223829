import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowRight, FiLock, FiUser } from 'react-icons/fi';

import api from '../../services/api';

import { Container, Form, Input, Button } from './styles';

import logo from '../../assets/images/logo.svg';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const data = {
      email,
      password,
    };

    await api
      .post('sessions', data)
      .then((response) => {
        const { user, token } = response.data;

        localStorage.setItem('@BeniConviteDigital:token', token);
        localStorage.setItem('@BeniConviteDigital:user', JSON.stringify(user));
      })
      .catch((err) => {
        alert('E-mail ou senha inválido.');
        return;
      });

    history.push('/events');
  }

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (token) {
      history.push('/events');
    }
  }, [history]);

  return (
    <Container>
      <Form>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
        <Input>
          <FiUser size={32} />
          <input
            type='text'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            placeholder='Usuário'
          />
        </Input>
        <Input>
          <FiLock size={32} />
          <input
            type='password'
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            placeholder='Senha'
          />
        </Input>

        <Button onClick={handleSubmit}>
          <FiArrowRight size={32} />
        </Button>
      </Form>
    </Container>
  );
};

export default SignIn;
