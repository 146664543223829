import React, { FormEvent, useEffect, useState } from 'react';
import { FiArrowLeft, FiCheck } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loader from '../../components/Loader';

import api from '../../services/api';
import logo from '../../assets/images/logo.svg';

import {
  Container,
  Header,
  HeaderNavButton,
  FormWrapper,
  FormField,
  InputLabel,
  Input,
} from './styles';

interface RouteParams {
  id: string;
}

interface Guest {
  id: string;
  name: string;
  phone: string;
  confirmed: boolean;
  presence: boolean;
}

interface Event {
  name: string;
  about: string;
  owner: string;
  event_date: string;
  confirmation_limit_date: string;
}

const AddGuest: React.FC = () => {
  // const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [guest, setGuest] = useState<Guest>();
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [guestName, setGuestName] = useState('');
  const [guestPhone, setGuestPhone] = useState('');

  const { id: event_id } = useParams<RouteParams>();

  const history = useHistory();

  useEffect(() => {
    if (guest) {
      const urlData = {
        domain: 'go.beniconvitedigital.com.br',
        originalURL: `https://benicovnitedigital.com.br/invitation/${event_id}/${guest.id}`,
      };

      api({
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: 'iahMwJR1PMMZL2KNrqrnq0phdoDq1YOo',
        },
        url: 'https://api.short.cm/links/public',
        data: JSON.stringify(urlData),
      }).then((response) => {
        console.log(response.data);
      });
    }

    api
      .get(`/events/${event_id}`)
      .then((response) => {
        setCurrentEvent(response.data);
        setLoading(false);
      })
      .catch(() => {
        localStorage.removeItem('@BeniConviteDigital:token');
        localStorage.removeItem('@BeniConviteDigital:user');
      });
  }, [event_id, guest]);

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  useEffect(() => {
    if (!guest) {
      return;
    }

    const whatsAppMessage = encodeURIComponent(`Olá, ${guest?.name}!\n
Você foi convidado(a) para ${currentEvent?.name}.\n
Para visualizar seu convite e confirmar sua presença, clique no link abaixo.\n
${process.env.REACT_APP_URL}/invitation/${event_id}/${guest?.id}\n
*Responda esta mensagem para habilitar o link do seu convite*`);

    window.open(
      `https://api.whatsapp.com/send?phone=55${guest?.phone}&text=${whatsAppMessage}`,
      '_blank'
    );
  }, [currentEvent?.name, event_id, guest]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!guestName || !guestPhone) {
      alert('Preencha os dados do convidado corretamente.');
      return;
    }

    const guestData = {
      name: guestName,
      phone: guestPhone,
      event_id: event_id,
      confirmed: false,
      presence: false,
    };

    const schema = Yup.object().shape({
      name: Yup.string().required('O nome do convidado é obrigatório').min(3),
      phone: Yup.number()
        .required('O telefone (WhatsApp) com DDD é obrigatório')
        .min(10),
      event_id: Yup.string().required('Ops, evento não encontrado.'),
      confirmed: Yup.boolean().default(false).required(),
      presence: Yup.boolean().default(false).required(),
    });

    await schema.validate(guestData, {
      abortEarly: false,
    });

    const addGuestResponse = await api.post('/guests', guestData);

    if (!addGuestResponse) {
      alert('Falha ao cadastrar convidado.');
      return;
    }

    setGuest(addGuestResponse.data);

    alert('Convidado cadastrado com sucesso!');

    history.push(`/event/${event_id}`);
  }

  return loading ? (
    <Loader />
  ) : (
    <Container>
      <Header>
        <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
      </Header>

      <HeaderNavButton>
        <Link to={`/event/${event_id}`}>
          <FiArrowLeft size={30} />
        </Link>
        <div onClick={handleSubmit}>
          <FiCheck size={30} />
        </div>
      </HeaderNavButton>
      <FormWrapper>
        <FormField>
          <InputLabel>Nome do convidado</InputLabel>
          <Input
            placeholder='Ex: José da Silva'
            value={guestName}
            onChange={(event) => setGuestName(event.target.value)}
          />
        </FormField>

        <FormField>
          <InputLabel>Telefone</InputLabel>
          <Input
            type='tel'
            placeholder='Ex: 19991929394'
            value={guestPhone}
            onChange={(event) => setGuestPhone(event.target.value)}
          />
        </FormField>
      </FormWrapper>
    </Container>
  );
};

export default AddGuest;
