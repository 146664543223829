import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  background: var(--green);
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin:0 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const Input = styled.div`
  width: 100%;
  height: 40px;
  margin: 16px 0;
  border-bottom: 1px solid var(--darkGray);
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--darkGray);
  }

  input {
    width: 100%;
    height: 60px;
    background: transparent;
    border-width:0px;
    border:none;
    padding: 16px 8px;
    margin-left: 8px;

    color: var(--darkGray);

    &::placeholder {
      color: var(--darkGray);
      opacity: 0.3;
    }
  }
`;

export const Label = styled.label`
  width: 100px;
  padding: 4px;
  color: var(--white);
  border-radius: 8px 0 0 8px;
  height: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: var(--white);
  background: var(--darkGray);
  border: 0;
  margin: 16px 0;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--white)
  }
`;