import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FiArrowLeft, FiCheck, FiUpload } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import api from '../../services/api';

import {
  Container,
  FormWrapper,
  FormField,
  InputLabel,
  Input,
  TextArea,
  Header,
  HeaderNavButton,
  EventImageContainer,
} from './styles';
import logo from '../../assets/images/logo.svg';
import Loader from '../../components/Loader';
import { utcToZonedTime } from 'date-fns-tz';

interface RouteParams {
  id: string;
}

interface Event {
  id: string;
  name: string;
  about: string;
  tour: string;
  owner: string;
  owner_contact: string;
  gift_list: string;
  info: string;
  event_date: string;
  confirmation_limit_date: string;
  place: string;
  latitude: string;
  longitude: string;
  images: Array<{
    id: string;
    url: string;
  }>;
}

const AddEvent: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<Event>();
  const [eventName, setEventName] = useState('');
  const [eventAbout, setEventAbout] = useState('');
  const [eventTour, setEventTour] = useState('');
  const [eventOwner, setEventOwner] = useState('');
  const [eventOwnerContact, setEventOwnerContact] = useState('');
  const [eventGiftList, setEventGiftList] = useState('');
  const [eventInfo, setEventInfo] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventConfirmationDate, setEventConfirmationDate] = useState('');
  const [eventPlace, setEventPlace] = useState('');
  const [eventLatitude, setEventLatitude] = useState('');
  const [eventLongitude, setEventLongitude] = useState('');
  const [eventImagesPreview, setEventImagesPreview] = useState<string[]>([]);
  const [eventImages, setEventImages] = useState<File[]>([]);

  const params = useParams<RouteParams>();
  const { id } = params;
  const history = useHistory();

  useEffect(() => {
    api.get(`events/${id}`).then((response) => {
      setEvent(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (event) {
      setEventName(event.name);
      setEventAbout(event.about);
      setEventDate(format(parseISO(event.event_date), "yyyy-MM-dd'T'HH:mm"));
      setEventConfirmationDate(
        format(parseISO(event.confirmation_limit_date), "yyyy-MM-dd'T'HH:mm")
      );
      setEventTour(event.tour);
      setEventOwner(event.owner);
      setEventOwnerContact(event.owner_contact);
      setEventGiftList(event.gift_list);
      setEventInfo(event.info);
      setEventPlace(event.place);
      setEventLatitude(event.latitude);
      setEventLongitude(event.longitude);
    }
    setLoading(false);
  }, [event]);

  useEffect(() => {
    const token = localStorage.getItem('@BeniConviteDigital:token');
    if (!token) {
      history.push('/');
    }
  }, [history]);

  function handleImageChange() {
    const input = document.getElementById('newImage');

    if (!input) {
      return;
    }

    input.click();
  }

  function handleSelectImages(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const selectedImages = Array.from(event.target.files);

    setEventImages(selectedImages);

    const selectedImagesPreview = selectedImages.map((image) => {
      return URL.createObjectURL(image);
    });

    setEventImagesPreview(selectedImagesPreview);
  }

  async function handleSubmit(event: FormEvent) {
    event?.preventDefault();

    const data = new FormData();

    data.append('name', eventName);
    data.append('about', eventAbout);
    data.append('tour', eventTour);
    data.append('owner', eventOwner);
    data.append('owner_contact', eventOwnerContact);
    data.append('gift_list', eventGiftList);
    data.append('info', eventInfo);
    data.append(
      'event_date',
      String(utcToZonedTime(eventDate, 'America/Sao_Paulo'))
    );
    data.append(
      'confirmation_limit_date',
      String(utcToZonedTime(eventConfirmationDate, 'America/Sao_Paulo'))
    );
    data.append('place', eventPlace);
    data.append('latitude', eventLatitude);
    data.append('longitude', eventLongitude);

    eventImages.forEach((image) => {
      data.append('images', image);
    });

    await api.put(`/events/${id}`, data);

    alert('Evento alterado com sucesso!');

    history.push('/');
  }

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header>
            <img src={logo} alt='Beni' style={{ margin: '28px 0 8px' }} />
          </Header>

          <HeaderNavButton>
            <Link to='/'>
              <FiArrowLeft size={30} />
            </Link>
            <div onClick={handleSubmit}>
              <FiCheck size={30} />
            </div>
          </HeaderNavButton>
          <FormWrapper>
            <FormField>
              {event?.images.map((image) => (
                <EventImageContainer onClick={handleImageChange}>
                  <img
                    key={
                      eventImagesPreview[0] ? eventImagesPreview[0] : image.id
                    }
                    src={
                      eventImagesPreview[0] ? eventImagesPreview[0] : image.url
                    }
                    alt={event.name}
                  />
                  <FiUpload />
                  <input
                    type='file'
                    name='newImage'
                    id='newImage'
                    onChange={handleSelectImages}
                  />
                </EventImageContainer>
              ))}
            </FormField>

            <FormField>
              <InputLabel>Nome do evento</InputLabel>
              <Input
                placeholder='Ex: Casamento de João e Maria'
                value={eventName}
                onChange={(event) => setEventName(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Descrição</InputLabel>
              <TextArea
                placeholder='Ex: Sobre o casamento de João e Maria'
                value={eventAbout}
                onChange={(event) => setEventAbout(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Data do Evento</InputLabel>
              <Input
                type='datetime-local'
                value={eventDate}
                onChange={(event) => setEventDate(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Data limite para confirmação de presença</InputLabel>
              <Input
                type='datetime-local'
                value={eventConfirmationDate}
                onChange={(event) =>
                  setEventConfirmationDate(event.target.value)
                }
              />
            </FormField>

            <FormField>
              <InputLabel>Tour</InputLabel>
              <Input
                placeholder='Ex: http://meutour.com.br'
                value={eventTour}
                onChange={(event) => setEventTour(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Responsável</InputLabel>
              <Input
                placeholder='Ex: Casamento de João e Maria'
                value={eventOwner}
                onChange={(event) => setEventOwner(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>WhatsApp do Responsável</InputLabel>
              <Input
                placeholder='Ex: 19991929394'
                value={eventOwnerContact}
                onChange={(event) => setEventOwnerContact(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Lista de Presentes</InputLabel>
              <Input
                placeholder='Ex: http://minhalista.com.br'
                value={eventGiftList}
                onChange={(event) => setEventGiftList(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Informações</InputLabel>
              <Input
                placeholder='Ex: Informações Adicionais'
                value={eventInfo}
                onChange={(event) => setEventInfo(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Nome do local</InputLabel>
              <Input
                placeholder='Ex: Chácara das rosas'
                value={eventPlace}
                onChange={(event) => setEventPlace(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Latitude</InputLabel>
              <Input
                placeholder='Ex: -22.55462'
                value={eventLatitude}
                onChange={(event) => setEventLatitude(event.target.value)}
              />
            </FormField>

            <FormField>
              <InputLabel>Longitude</InputLabel>
              <Input
                placeholder='Ex: -45.55893'
                value={eventLongitude}
                onChange={(event) => setEventLongitude(event.target.value)}
              />
            </FormField>
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default AddEvent;
