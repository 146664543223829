import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px 20px;
  background: var(--green);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.header`
  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const HeaderNavButton = styled.button`
  max-width: 600px;
  width: 100%;
  height: 60px;
  background: transparent;
  border: 0;
  margin: 20px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    width: 60px;
    height: 60px;
    background: var(--darkGray);
    border: 0;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--white);
    }
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  background: transparent;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.div`
  list-style: none;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  li:first-child {
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-weight: bold;
    background: transparent;
    color: var(--darkGray);
    border: 0;
  }

  a {
    height: 60px;
    width: 100%;
    text-decoration: none;
    color: var(--white);

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      min-width: 100%;
    }
  }

  li {
    height: 60px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 8px 0;
    background: var(--darkGray);
    color: var(--white);
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span:first-child {
      width: 100%;
    }
    span:last-child {
      width: 60px;
    }
    span {
      margin: 0 8px;
      width: 120px;

      svg {
        cursor: pointer;
        margin: 0 4px;
        color: var(--white);
      }
    }
  }
`;

export const AddEventButton = styled.button`
  position: fixed;
  right: 40px;
  bottom: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border-radius: 16px;
  border: 0;
  background: var(--secondaryGradient);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

  cursor: pointer;

  a {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--white);
    }
  }
`;