import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  max-height: 100vh;
  height: 100%;

  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const AuthorizedMessage = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background: #44bd32;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
  }

  span {
    font-size: 18px;
    margin: 16px 0;
  }

  div {
    min-width: 80px;
    min-height: 80px;

    border-radius: 50%;
    background: #4cd137;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NotFoundMessage = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background: #c23616;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  div {
    min-width: 80px;
    min-height: 80px;

    border-radius: 50%;
    background: #e84118;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const UnauthorizedMessage = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background: #e1b12c;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  div {
    min-width: 80px;
    min-height: 80px;

    border-radius: 50%;
    background: #fbc531;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
