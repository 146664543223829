import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  background: #fff;
`;

export const BackgroundImageOpacity = styled.img`
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.25;
`;

export const InvitationBackground = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackgroundImageBlur = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.25;
  filter: blur(6px);
  z-index: 2;
`;

export const Container = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  margin: 24px;
  border-radius: 40px;  
  font-weight: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const EventHeader = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 40px;
  z-index: 4;
  object-fit: cover;
`;

export const EventContainer = styled.div`
  color: #545454;
  padding: 180px 24px 24px;
  margin-top: -160px;
  width: 100%;
  height: auto;
  background: transparent;
  border-radius: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
`;

export const Separator = styled.div`
  width: 80%;
  height: 2px;
  margin: 0 24px;
  background: #fff;
  opacity: 1;
`;

export const EventActions = styled.div`
  margin-top: -40px;
  width: 100%;
  height: 80px; 

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoingButton = styled.button`
  width: 100px;
  height: 100px;
  background: linear-gradient(123deg, #afd7b9, #9cc1a6);
  border-radius: 20px;
  margin: 0 8px;
  padding: 16px 8px;
  font-size: 18px;
  border: 0;
  color: rgba(255, 255, 255, 0.60);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  z-index: 30;
`;

export const NotGoingButton = styled.button`
  width: 100px;
  height: 100px;
  background: linear-gradient(123deg, #a6a49e, #878580);
  border-radius: 20px;
  margin: 0 8px;
  padding: 16px 8px;
  font-size: 18px;
  border:0;
  color: rgba(255, 255, 255, 0.60);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  z-index: 30;
`;

export const EventDescription = styled.div`
  width: 100%;
  height: auto;
  border-radius: 16px;
  background: rgba(255,255,255, 0.30);
  padding: 16px;
  margin: 32px 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: #555;
    padding: 8px 0;
    font-size: 16px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;

export const EventTitle = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;

export const EventLocation = styled.div`
  margin-top: 8px;

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #555;

    display: flex;
    align-items: center;
  }
`;

export const EventDate = styled.div`
  width: 100%;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EventGiftList = styled.div`
  width: 100%;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const EventInformation = styled.div`
  width: 100%;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const EventOwner = styled.div`
  width: 100%;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: #555;
    font-weight: bold;
    padding: 8px 0;
    font-size: 16px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  span {
    text-align: center;
  }
`;

export const Logo = styled.img`
  width: 100px;
  margin: 8px 0;
`;