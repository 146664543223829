import { FiLoader } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
  background: var(--green);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  h4 {
    font-size: 22px;
    margin-top: 20px;
    color: var(--darkGray);
  }
`;

export const Header = styled.header`
  width: 100%;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: var(--darkGray);
    padding: 0 8px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-top:32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  input {
    height: 40px;
    border-radius: 8px 0 0 8px;
    border: 1px solid #666;
    padding: 8px;
    width: 100%;

    &::placeholder {
      color: #aaa;
    }
  }

  button {
    height: 40px;
    border-radius: 0 8px 8px 0;
    border: 1px solid #666;
    color: #ffffff;
    background-color: var(--darkGray);
    cursor: pointer;
    padding: 8px;
    width: 120px;
  }
`;

export const Loader = styled(FiLoader)`
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
`;