import React from 'react';

import GlobalStyle from './styles/GlobalStyle';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  );
};

export default App;
